const useCustomDates = (t) => {
    const getLabel = (key) => t(`common.${key}`);

    return {
        lastDay: {
            label: getLabel('dates.lastDay'),
            unit: 'days',
            value: 1,
        },
        lastWeek: {
            label: getLabel('dates.lastWeek'),
            unit: 'days',
            value: 7,
        },
        lastMonth: {
            label: getLabel('dates.lastMonth'),
            unit: 'month',
            value: 1,
        },
        lastSemester: {
            label: getLabel('dates.lastSemester'),
            unit: 'months',
            value: 6,
        },
        lastYear: {
            label: getLabel('dates.lastYear'),
            unit: 'months',
            value: 12,
        },
    };
};

const useDefaultPeriod = (t) => {
    const customDates = useCustomDates(t);
    return customDates.lastMonth;
};

const currVersion = 'v3.25.2';

const useConsumersTableColumns = (t, dso) => {
    const getLabel = (key) => t(`common.table.legend.${key}`);

    return [
        { id: 'name', label: getLabel('name'), width: '35%' },
        {
            id: 'dsoId',
            label: getLabel(dso ? `dsoId${dso}` : 'dsoIdENEDIS'),
            width: '25%',
        },
        { id: 'globalRate', label: getLabel('consumption'), width: '15%' },
        { id: 'localRate', label: getLabel('selfProduction'), width: '15%' },
        { id: 'details', label: '', width: '10%' },
    ];
};

const useProducersTableColumns = (t, dso) => {
    const getLabel = (key) => t(`common.table.legend.${key}`);

    return [
        { id: 'name', label: getLabel('name'), width: '35%' },
        {
            id: 'dsoId',
            label: getLabel(dso ? `dsoId${dso}` : 'dsoIdENEDIS'),
            width: '25%',
        },
        { id: 'globalRate', label: getLabel('production'), width: '15%' },
        { id: 'localRate', label: getLabel('selfConsumption'), width: '15%' },
        { id: 'details', label: '', width: '10%' },
    ];
};

const columnTypes = {
    BILLING: 'billingDetail',
    ENEDIS: 'enedisDetail',
    DSO: 'dsoDetails',
};

const useParticipantsTableColumns = (t, selectedOperation) => {
    const getLabel = (key) => t(`common.${key}`);
    switch (selectedOperation?.dso) {
        case 'ENEDIS':
        case 'SER':
        case 'SRD':
        case 'GREENALP':
            return {
                [columnTypes.ENEDIS]: [
                    {
                        id: 'enedis_entityType',
                        name: 'entityType',
                        label: getLabel('table.legend.entityType'),
                        minWidth: 150,
                    },
                    {
                        id: 'enedis_name',
                        name: 'name',
                        label: getLabel('table.legend.identity'),
                        minWidth: 150,
                    },
                    {
                        id: 'enedis_prm',
                        name: 'prm',
                        label: getLabel('table.legend.prmNumber'),
                        minWidth: 120,
                    },
                    {
                        id: 'enedis_padt',
                        name: 'padt',
                        label: getLabel('table.legend.padtNumber'),
                        minWidth: 120,
                    },
                    {
                        id: 'enedis_activation_range_start',
                        name: 'activationRangeStart',
                        label: getLabel('table.legend.entryDate'),
                        minWidth: 120,
                    },
                    {
                        id: 'enedis_activation_range_end',
                        name: 'activationRangeEnd',
                        label: getLabel('table.legend.exitDate'),
                        minWidth: 120,
                    },
                    {
                        id: 'enedis_address',
                        name: 'address',
                        label: getLabel('table.legend.address'),
                        minWidth: 150,
                    },
                    {
                        id: 'enedis_city',
                        name: ['city', 'postCode'],
                        label: getLabel('table.legend.city'),
                        labels: [
                            getLabel('table.legend.city'),
                            getLabel('table.legend.postCode'),
                        ],
                        minWidth: 150,
                    },
                    {
                        id: 'dossier_status',
                        name: 'folder_status',
                        label: getLabel('table.legend.status'),
                        minWidth: 100,
                    },
                    {
                        id: 'account_status',
                        name: 'account_status',
                        label: getLabel('table.legend.account'),
                        minWidth: 100,
                    },
                ],
                [columnTypes.BILLING]: [
                    {
                        id: 'billing_name',
                        name: 'name',
                        label: getLabel('table.legend.identity'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_address',
                        name: 'address',
                        label: getLabel('table.legend.address'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_postCode',
                        name: ['city', 'postCode'],
                        label: getLabel('table.legend.city'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_phoneNumber',
                        name: ['email', 'phoneNumber'],
                        label: getLabel('table.legend.contact'),
                        minWidth: 100,
                    },
                ],
                [columnTypes.DSO]: [],
            };
        case 'SIBELGA':
            return {
                [columnTypes.ENEDIS]: [],
                [columnTypes.DSO]: [
                    {
                        id: 'dso_name',
                        name: 'name',
                        label: getLabel('table.legend.identity'),
                        getValue: (participant) => participant?.name,
                        minWidth: 150,
                    },
                    {
                        id: 'dso_identificer',
                        name: 'dsoId',
                        label: getLabel('table.legend.sibelga.ean'),
                        getValue: (participant) => participant?.dsoId,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_activation_range_start',
                        name: 'activationRangeStart',
                        label: getLabel('table.legend.entryDate'),
                        getValue: (participant) =>
                            participant?.activationRange.length > 0
                                ? participant?.activationRange[0].value
                                : null,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_activation_range_end',
                        name: 'activationRangeEnd',
                        label: getLabel('table.legend.exitDate'),
                        getValue: (participant) =>
                            participant?.activationRange.length > 1
                                ? participant?.activationRange[1].value
                                : null,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_address',
                        name: 'address',
                        label: getLabel('table.legend.address'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.address,
                        minWidth: 150,
                    },
                    {
                        id: 'dso_city',
                        name: ['city', 'postCode'],
                        label: getLabel('table.legend.city'),
                        labels: [
                            getLabel('table.legend.city'),
                            getLabel('table.legend.postCode'),
                        ],
                        getValue: (participant) => [
                            participant?.dsoDetails?.city,
                            participant?.dsoDetails?.postCode,
                        ],
                        minWidth: 150,
                    },
                    {
                        id: 'dossier_status',
                        name: 'folder_status',
                        label: getLabel('table.legend.status'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.folder_status,
                        minWidth: 100,
                    },
                    {
                        id: 'account_status',
                        name: 'account_status',
                        label: getLabel('table.legend.account'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.account_status,
                        minWidth: 100,
                    },
                ],
                [columnTypes.BILLING]: [],
            };
        case 'FAKE':
        default:
            return {
                [columnTypes.ENEDIS]: [],
                [columnTypes.DSO]: [
                    {
                        id: 'dso_name',
                        name: 'name',
                        label: getLabel('table.legend.identity'),
                        getValue: (participant) => participant?.name,
                        minWidth: 150,
                    },
                    {
                        id: 'dso_identificer',
                        name: 'dsoId',
                        label: getLabel('table.legend.dsoIdentifier'),
                        getValue: (participant) => participant?.dsoId,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_activation_range_start',
                        name: 'activationRangeStart',
                        label: getLabel('table.legend.entryDate'),
                        getValue: (participant) =>
                            participant?.activationRange.length > 0
                                ? participant?.activationRange[0].value
                                : null,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_activation_range_end',
                        name: 'activationRangeEnd',
                        label: getLabel('table.legend.exitDate'),
                        getValue: (participant) =>
                            participant?.activationRange.length > 1
                                ? participant?.activationRange[1].value
                                : null,
                        minWidth: 120,
                    },
                    {
                        id: 'dso_address',
                        name: 'address',
                        label: getLabel('table.legend.address'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.address,
                        minWidth: 150,
                    },
                    {
                        id: 'dso_city',
                        name: ['city', 'postCode'],
                        label: getLabel('table.legend.city'),
                        labels: [
                            getLabel('table.legend.city'),
                            getLabel('table.legend.postCode'),
                        ],
                        getValue: (participant) => [
                            participant?.dsoDetails?.city,
                            participant?.dsoDetails?.postCode,
                        ],
                        minWidth: 150,
                    },
                    {
                        id: 'dossier_status',
                        name: 'folder_status',
                        label: getLabel('table.legend.status'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.folder_status,
                        minWidth: 100,
                    },
                    {
                        id: 'account_status',
                        name: 'account_status',
                        label: getLabel('table.legend.account'),
                        getValue: (participant) =>
                            participant?.dsoDetails?.account_status,
                        minWidth: 100,
                    },
                ],
                [columnTypes.BILLING]: [
                    {
                        id: 'billing_name',
                        name: 'name',
                        label: getLabel('table.legend.identity'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_address',
                        name: 'address',
                        label: getLabel('table.legend.address'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_postCode',
                        name: ['city', 'postCode'],
                        label: getLabel('table.legend.city'),
                        minWidth: 150,
                    },
                    {
                        id: 'billing_phoneNumber',
                        name: ['email', 'phoneNumber'],
                        label: getLabel('table.legend.contact'),
                        minWidth: 100,
                    },
                ],
            };
    }
};

const userTypes = {
    CONSUMERS: 'consumers',
    PRODUCERS: 'producers',
};
const useUserTitle = (t) => {
    const getLabel = (key) => t(`common.${key}`);
    return {
        consumers: getLabel('words.consumers'),
        producers: t(`common.words.producers`),
    };
};

const userUrl = {
    consumers: 'cons',
    producers: 'prod',
};

const useEntityTypes = (t) => {
    return [
        {
            value: 'Professionnel - industriel',
            label: t('common.entityTypes.proIndustrial'),
        },
        {
            value: 'Professionnel - tertiaire public',
            label: t('common.entityTypes.proTertiary'),
        },
        {
            value: 'Professionnel - tertiaire privé',
            label: t('common.entityTypes.proTertiaryPrivate'),
        },
        {
            value: 'Professionnel - artisan',
            label: t('common.entityTypes.proArtisan'),
        },
        {
            value: 'Professionnel - agriculteur',
            label: t('common.entityTypes.proAgricol'),
        },
        {
            value: 'Professionnel - autre',
            label: t('common.entityTypes.proOther'),
        },
        {
            value: 'Particulier - maison individuelle',
            label: t('common.entityTypes.partIndiv'),
        },
        {
            value: 'Particulier - habitat collectif copropriété',
            label: t('common.entityTypes.partCollective'),
        },
        {
            value: 'Particulier - habitat collectif social',
            label: t('common.entityTypes.partCollectiveSocial'),
        },
        {
            value: 'Particulier - autre',
            label: t('common.entityTypes.partOther'),
        },
    ];
};

// segments = [segmentConsumer],or[segmentProducer]
const segments = {
    consumer: [
        { id: 'C1', value: 'C1', label: 'C1' },
        { id: 'C2', value: 'C2', label: 'C2' },
        { id: 'C3', value: 'C3', label: 'C3' },
        { id: 'C4', value: 'C4', label: 'C4' },
        { id: 'C5', value: 'C5', label: 'C5' },
    ],
    producer: [
        { id: 'P1', value: 'P1', label: 'P1' },
        { id: 'P2', value: 'P2', label: 'P2' },
        { id: 'P3', value: 'P3', label: 'P3' },
        { id: 'P4 en surplus', value: 'P4 en surplus', label: 'P4 en surplus' },
        {
            id: 'P4 en totalité',
            value: 'P4 en totalité',
            label: 'P4 en totalité',
        },
    ],
};
const useEnergyTypes = (t) => {
    const getLabel = (key) => t(`common.${key}`);
    return [
        {
            value: 'producer',
            label: getLabel('words.producer'),
        },
        {
            value: 'consumer',
            label: getLabel('words.consumer'),
        },
    ];
};

const useProductionTypes = (t) => {
    const getLabel = (key) => t(`common.${key}`);
    return [
        {
            value: 12,
            label: getLabel('productionTypes.solar'),
        },
        {
            value: 13,
            label: getLabel('productionTypes.hydro'),
        },
        {
            value: 10,
            label: getLabel('productionTypes.wind'),
        },
        {
            value: 11,
            label: getLabel('productionTypes.cogeneration'),
        },
    ];
};

const drawerWidth = 300;
const closeDrawerWidth = 56;

const getSelectedRange = (selectedDates) => {
    const diff = selectedDates.to.diff(selectedDates.from, 'months', true);
    let selectedRange = 1;
    if (diff > 1 && diff <= 3) selectedRange = 3;
    else if (diff > 3 && diff <= 6) selectedRange = 6;
    else if (diff > 6 && diff <= 12) selectedRange = 12;
    return selectedRange;
};

const crumbTypes = {
    LINK: 'link',
    CONST: 'const',
    MENU: 'menu',
};

const featureToggles = {
    SEPA: process.env.REACT_APP_FEATURE_TOGGLE === 'true',
};

export {
    segments,
    useCustomDates,
    useDefaultPeriod,
    currVersion,
    useConsumersTableColumns,
    useProducersTableColumns,
    userTypes,
    useUserTitle,
    userUrl,
    useEntityTypes,
    useEnergyTypes,
    useProductionTypes,
    drawerWidth,
    closeDrawerWidth,
    getSelectedRange,
    crumbTypes,
    useParticipantsTableColumns,
    columnTypes,
    featureToggles,
};
