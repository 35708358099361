import {
    CREATE_PARTICIPANT_PARAMETER,
    GET_ALLOCATION_ALGORITHM_CONFIG,
    GET_OPERATION_SEASONALITY,
    GET_PARTICIPANTS_ALLOCATION_PARAMETERS,
    INIT_NEW_ALLOCATION_ALGORITHM_CONFIG,
    INIT_NEW_PARTICIPANTS_PARAMETERS,
    UPDATE_ENERGY_ALLOCATION,
    UPDATE_OPERATION_SEASONALITY,
} from '../../redux/reducers/constants';
import {
    all,
    call,
    getContext,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import {
    setError,
    setNewAllocationAlgorithmType,
    setNewParticipantsParameters,
    setNotif,
    setPersistedAllocationAlgorithmConfigType,
    setPersistedParticipantsParameters,
    setPersistedSeasonality,
} from '../../redux/actions';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import {
    selectActiveConsumersSinceLastMensiversary,
    selectActiveProducersSinceLastMensiversary,
} from '../../redux/selectors/participantSelectors';
import {
    selectNewAllocationAlgorithmType,
    selectNewParticipantsParameters,
    selectPersistedAllocationAlgorithmConfig,
    selectPersistedParticipantsParameters,
} from '../../redux/selectors/energyAllocationSelector';
import {
    AllocationAlgorithmTypeEnum,
    doesAlgoNeedParams,
} from '../../../../app-config';

function* getAllocationAlgorithmConfig() {
    const operation = yield select(selectSelectedOperation);
    try {
        const energyAllocationGateway = yield getContext(
            'energyAllocationGateway'
        );
        const config = yield call(
            energyAllocationGateway.getAllocationAlgorithmConfig,
            operation.id
        );
        yield put(setPersistedAllocationAlgorithmConfigType(config));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* initNewAllocationAlgorithmConfig() {
    const persistedAllocationAlgorithmConfig = yield select(
        selectPersistedAllocationAlgorithmConfig
    );
    yield put(
        setNewAllocationAlgorithmType(persistedAllocationAlgorithmConfig?.type)
    );
}

function* getParticipantsAllocationParameters() {
    const [config, consumers, producers] = yield all([
        select(selectPersistedAllocationAlgorithmConfig),
        select(selectActiveConsumersSinceLastMensiversary),
        select(selectActiveProducersSinceLastMensiversary),
    ]);
    const energyAllocationGateway = yield getContext('energyAllocationGateway');
    try {
        let parameters = [];
        if (config && doesAlgoNeedParams(config.type)) {
            if (config.type === AllocationAlgorithmTypeEnum.BY_POOL) {
                parameters = yield call(
                    energyAllocationGateway.getPools,
                    config.id
                );
            } else {
                const consumersIds = consumers.map((consumer) => consumer.id);
                const producersIds = producers.map((producer) => producer.id);
                parameters = yield call(
                    energyAllocationGateway.getParticipantsParameters,
                    config.id,
                    consumersIds, // Pourquoi on donne aussi les consumerIds et producersIds
                    producersIds
                );
            }
        }
        yield put(setPersistedParticipantsParameters(parameters));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* initNewParticipantsParameters() {
    const [newAlgorithmType, persistedAllocationAlgorithmConfig] = yield all([
        select(selectNewAllocationAlgorithmType),
        select(selectPersistedAllocationAlgorithmConfig),
    ]);
    let params = {};
    if (persistedAllocationAlgorithmConfig?.type === newAlgorithmType) {
        const participantParameters = yield select(
            selectPersistedParticipantsParameters
        );
        if (participantParameters) {
            params = { ...participantParameters };
        }
    }
    yield put(setNewParticipantsParameters(params));
}

function* sendUpdatedPoolsToBack(poolParams) {
    const energyAllocationGateway = yield getContext('energyAllocationGateway');
    const persistedConfig = yield select(
        selectPersistedAllocationAlgorithmConfig
    );

    yield call(
        energyAllocationGateway.createPools,
        persistedConfig.id,
        poolParams
    );
}

function* sendUpdatedParamsToBack(newParticipantsParameters) {
    const energyAllocationGateway = yield getContext('energyAllocationGateway');
    const [persistedConfig, persistedParams] = yield all([
        select(selectPersistedAllocationAlgorithmConfig),
        select(selectPersistedParticipantsParameters),
    ]);
    for (const entries of Object.entries(newParticipantsParameters)) {
        let [id, values] = entries;
        if (typeof values === 'object') {
            // Ungroupped priority
            const producerId = id;
            for (const [key, value] of Object.entries(values)) {
                const consumerId = key;
                const hasChanged =
                    persistedParams[producerId][consumerId] !== value;
                if (!hasChanged) continue;
                console.log({
                    hasChanged,
                    persistedParams,
                    producerId,
                    consumerId,
                    value,
                });

                yield call(
                    energyAllocationGateway.createParticipantParameter,
                    consumerId,
                    producerId,
                    persistedConfig.id,
                    value
                );
            }
        } else {
            const consumerId = id;
            const hasChanged = persistedParams[consumerId] !== values;
            if (!hasChanged) continue;

            yield call(
                energyAllocationGateway.createParticipantParameter,
                consumerId,
                undefined,
                persistedConfig.id,
                values
            );
        }
    }
}

function* updateEnergyAllocation() {
    const [
        operation,
        persistedConfig,
        newAlgorithmType,
        newParticipantsParameters,
    ] = yield all([
        select(selectSelectedOperation),
        select(selectPersistedAllocationAlgorithmConfig),
        select(selectNewAllocationAlgorithmType),
        select(selectNewParticipantsParameters),
    ]);
    const energyAllocationGateway = yield getContext('energyAllocationGateway');
    const hasConfigTypeChanged = persistedConfig?.type !== newAlgorithmType;
    const needsParams = doesAlgoNeedParams(newAlgorithmType);
    try {
        if (hasConfigTypeChanged) {
            yield call(
                energyAllocationGateway.createConfig,
                operation.id,
                newAlgorithmType,
                needsParams ? newParticipantsParameters : null
            );
        } else if (needsParams) {
            if (newAlgorithmType === AllocationAlgorithmTypeEnum.BY_POOL) {
                yield* sendUpdatedPoolsToBack(newParticipantsParameters);
            } else {
                yield* sendUpdatedParamsToBack(newParticipantsParameters);
            }
        }
        yield call(getAllocationAlgorithmConfig);
        yield put(
            setNotif({
                message:
                    'La configuration des coefficients a bien été enregistrée',
            })
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createParticipantParameter(action) {
    const { consumerId, producerId, configId, value } = action;
    const energyAllocationGateway = yield getContext('energyAllocationGateway');
    try {
        yield call(
            energyAllocationGateway.createParticipantParameter,
            consumerId,
            producerId,
            configId,
            value
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getOperationSeasonality() {
    const operation = yield select(selectSelectedOperation);
    try {
        const energyAllocationGateway = yield getContext(
            'energyAllocationGateway'
        );
        const seasonality = yield call(
            energyAllocationGateway.getOperationSeasonality,
            operation.id
        );
        yield put(setPersistedSeasonality(seasonality));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateOperationSeasonality(action) {
    const { operationId, seasonality } = action;
    const energyAllocationGateway = yield getContext('energyAllocationGateway');

    yield call(
        energyAllocationGateway.createOperationSeasonality,
        operationId,
        seasonality
    );

    yield put(setPersistedSeasonality(seasonality));
}

function* getAllocationAlgorithmConfigSaga() {
    yield takeLatest(
        GET_ALLOCATION_ALGORITHM_CONFIG,
        getAllocationAlgorithmConfig
    );
}

function* initNewAllocationAlgorithmConfigSaga() {
    yield takeLatest(
        INIT_NEW_ALLOCATION_ALGORITHM_CONFIG,
        initNewAllocationAlgorithmConfig
    );
}

function* getParticipantsAllocationParametersSaga() {
    yield takeLatest(
        GET_PARTICIPANTS_ALLOCATION_PARAMETERS,
        getParticipantsAllocationParameters
    );
}

function* initNewParticipantsParametersSaga() {
    yield takeLatest(
        INIT_NEW_PARTICIPANTS_PARAMETERS,
        initNewParticipantsParameters
    );
}

function* createParticipantParameterSaga() {
    yield takeLatest(CREATE_PARTICIPANT_PARAMETER, createParticipantParameter);
}

function* updateEnergyAllocationSaga() {
    yield takeLatest(UPDATE_ENERGY_ALLOCATION, updateEnergyAllocation);
}

function* getOperationSeasonalitySaga() {
    yield takeLatest(GET_OPERATION_SEASONALITY, getOperationSeasonality);
}

function* updateOperationSeasonalitySaga() {
    yield takeLatest(UPDATE_OPERATION_SEASONALITY, updateOperationSeasonality);
}

const energyAllocationSagas = [
    getAllocationAlgorithmConfigSaga,
    initNewAllocationAlgorithmConfigSaga,
    getParticipantsAllocationParametersSaga,
    initNewParticipantsParametersSaga,
    updateEnergyAllocationSaga,
    createParticipantParameterSaga,
    getOperationSeasonalitySaga,
    updateOperationSeasonalitySaga,
];

export default energyAllocationSagas;
