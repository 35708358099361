export class ParticipantMapper {
    id;
    dsoId;
    name;
    energyType;
    activationRange;
    operationId;
    isProsumer;
    dsoStatus;
    dsoAction;
    userId;
    user;
    dsoDetails;
    enedisDetail;
    billingDetail;

    constructor(participant) {
        this.id = participant.id;
        this.dsoId =
            participant.dsoDetails?.dsoIdentifier ||
            participant.enedisDetail?.prm;
        this.name =
            participant.name ||
            participant.enedisDetail?.name ||
            participant.dsoDetails?.name ||
            participant.billingDetail?.name;
        this.energyType = participant.energyType;
        this.activationRange =
            participant.activationRange ||
            participant.enedisDetail?.activationRange;
        this.operationId = participant.operationId;
        this.isProsumer = participant.isProsumer;
        this.dsoStatus = participant.enedisInfoStatus;
        this.dsoAction = participant.enedisActionType;
        this.dsoDetails = participant.dsoDetails;
        this.enedisDetail = participant.enedisDetail;
        this.billingDetail = participant.billingDetail;
        this.user = participant.user;
        this.userId = participant.user?.id;
    }
}
