import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { appsRoutes } from './app-config';
import React, { lazy, Suspense } from 'react';
import Button from '@material-ui/core/Button';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import configureStore from './app/corelogic/redux/configureStore';
import adminStore from './admin/configureStore';
import ErrorBoundary from './app/adapters/primary/ui/shared/ErrorBoundary';
import AuthenticationGateway from './app/adapters/secondary/gateways/authentication/AuthenticationGateway';
import OperationGateway from './app/adapters/secondary/gateways/operation/OperationGateway';
import MeasurementGateway from './app/adapters/secondary/gateways/measurement/MeasurementGateway';
import CustomizedThemeProvider from './app/adapters/primary/ui/CustomizedThemeProvider';
import BillingGateway from './app/adapters/secondary/gateways/billing/BillingGateway';
import ParticipantGateway from './app/adapters/secondary/gateways/participant/ParticipantGateway';
import AccountGateway from './app/adapters/secondary/gateways/account/AccountGateway';
import NavigationGateway from './app/adapters/secondary/gateways/navigation/NavigationGateway';
import BillCheckGateway from './app/adapters/secondary/gateways/billCheck/BillCheckGateway';
import CommunicationGateway from './app/adapters/secondary/gateways/communication/CommunicationGateway';
import { EnergyAllocationGateway } from './app/adapters/secondary/gateways/energyAllocation/EnergyAllocationGateway';
import GlobalSnackbar from './app/adapters/primary/ui/enoapp/components/GlobalSnackbar';
import PaymentGateway from './app/adapters/secondary/gateways/payment/PaymentGateway';
import UploadGateway from './app/adapters/secondary/gateways/upload/UploadGateway';

const Admin = lazy(() => import('./admin/App'));
const Eno = lazy(() => import('./app/adapters/primary/ui/App'));

const store = configureStore({
    ...adminStore,
    sagaContext: {
        authenticationGateway: new AuthenticationGateway(),
        operationGateway: new OperationGateway(),
        measurementGateway: new MeasurementGateway(),
        billingGateway: new BillingGateway(),
        participantGateway: new ParticipantGateway(),
        accountGateway: new AccountGateway(),
        navigationGateway: new NavigationGateway(),
        billCheckGateway: new BillCheckGateway(),
        communicationGateway: new CommunicationGateway(),
        energyAllocationGateway: new EnergyAllocationGateway(),
        paymentGateway: new PaymentGateway(),
        uploadGateway: new UploadGateway(),
    },
});

const Routes = () => {
    const notistackRef = React.createRef();
    const onClickClose = (key) => () => {
        notistackRef.current.handleDismissSnack(key);
    };
    return (
        <Suspense fallback={<div></div>}>
            {/* We wrap entire app in provider and configure the store for react-admin manually */}
            <Provider store={store}>
                <Router>
                    <CustomizedThemeProvider>
                        <SnackbarProvider
                            ref={notistackRef}
                            action={(key) => (
                                <Button
                                    style={{ color: 'white' }}
                                    size="small"
                                    onClick={onClickClose(key)}
                                >
                                    Ok
                                </Button>
                            )}
                            maxSnack={10}
                        >
                            <GlobalSnackbar />
                            <ErrorBoundary>
                                <Switch>
                                    <Route
                                        path={appsRoutes.admin}
                                        component={Admin}
                                    />
                                    <Route path="/" component={Eno} />
                                </Switch>
                            </ErrorBoundary>
                        </SnackbarProvider>
                    </CustomizedThemeProvider>
                </Router>
            </Provider>
        </Suspense>
    );
};

export default Routes;
