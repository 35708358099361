import client from '../../libs/client';

export default class BillingGateway {
    getConfigs = async (operationId) => {
        const { data: configs } = await client.get(
            `${this.getConfigUrlBase(operationId)}`
        );
        return configs;
    };
    getContracts = async (operationId) => {
        const { data: contracts } = await client.get(
            `${this.getContractUrlBase(operationId)}`
        );
        return contracts;
    };

    getProducerConfigs = async (operationId, producerId) => {
        const { data: configs } = await client.get(
            `${this.getConfigUrlBase(operationId)}/by-producer/${producerId}`
        );
        return configs;
    };

    getProducerContracts = async (operationId, producerId) => {
        const { data: configs } = await client.get(
            `${this.getContractUrlBase(operationId)}/by-producer/${producerId}`
        );
        return configs;
    };
    createConfig = async (config, operationId) => {
        const { data } = await client.post(
            `${this.getConfigUrlBase(operationId)}`,
            config
        );
        return data;
    };

    createContract = async (contract, operationId) => {
        const { data } = await client.post(
            `${this.getContractUrlBase(operationId)}`,
            contract
        );
        return data;
    };

    deleteConfig = async (operationId, configId) => {
        const { data: contract } = await client.delete(
            `${this.getConfigUrlBase(operationId)}/${configId}`
        );
        return contract;
    };

    deleteContract = async (operationId, contractId) => {
        const { data: contract } = await client.delete(
            `${this.getContractUrlBase(operationId)}/${contractId}`
        );
        return contract;
    };

    editConfig = async (config, operationId, configType, isNewConfig) => {
        const { id } = config;
        const body = {
            ...config,
            isNewConfig,
        };

        const { data } = await client.put(
            `${this.getConfigUrlBase(operationId)}/${id}`,
            body
        );
        return data;
    };

    getExcessBuyers = async (operationId) => {
        const { data: contract } = await client.get(
            `${this.getConfigUrlBase(operationId)}/excess-buyers`
        );
        return contract;
    };

    getConfigUrlBase = (operationId) => {
        return `/operations/${operationId}/billing-config`;
    };

    getContractUrlBase = (operationId) => {
        return `/operations/${operationId}/billing-contracts`;
    };

    getBillDocumentPresignedUrl = async (bill) => {
        return await client.get(`/bills/${bill.id}/presigned-url`);
    };

    getMultipleBillDocumentsPresignedUrlObjects = async (bills) => {
        const billsChunks = this.splitArrayIntoChunks(bills);
        const contentsWithTitles = [];
        for (let i = 0; i < billsChunks.length; i++) {
            const responses = await Promise.all(
                billsChunks[i].map(async (bill) => {
                    return {
                        title: bill.document.name,
                        content: await client.get(
                            `/bills/${bill.id}/presigned-url`
                        ),
                    };
                })
            );
            contentsWithTitles.push(...responses);
        }
        return contentsWithTitles;
    };

    splitArrayIntoChunks = (elements) => {
        const CHUNK_LENGTH = 10;
        let chunks = [];
        for (let i = 0; i < elements.length; i += CHUNK_LENGTH) {
            chunks.push(elements.slice(i, i + CHUNK_LENGTH));
        }
        return chunks;
    };

    getBills = async (operationId, configType) => {
        const { data: bills } = await client.get(
            `/operations/${operationId}/bills?billType=${configType}`
        );
        return bills;
    };

    getParticipantBills = async (participantId, configType) => {
        const { data: bills } = await client.get(
            `/participants/${participantId}/bills?billType=${configType}`
        );
        return bills;
    };

    updateBills = async (operationId, bills) => {
        return Promise.all(
            bills.map((bill) =>
                client.put(`operations/${operationId}/bills/${bill.id}`, bill)
            )
        );
    };

    editExcessBill = async (
        operationId,
        { startDate, endDate, producerId }
    ) => {
        await client.post(`/operations/${operationId}/bills/edit-excess-bill`, {
            startDate,
            endDate,
            producerId,
        });
    };

    uploadExcessContract = async (operationId, { contract, producerId }) => {
        const formData = new FormData();
        formData.append('file', contract);
        formData.append('producerId', producerId);
        await client.post(
            `/operations/${operationId}/bills/upload-excess-contract`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    };

    uploadExcessTemplate = async (operationId, { template, producerId }) => {
        const formData = new FormData();
        formData.append('file', template);
        formData.append('producerId', producerId);
        await client.post(
            `/operations/${operationId}/bills/upload-excess-template`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    };

    downloadBillingStatus = async (producerId, billingPeriodEnd) => {
        const url = `${process.env.REACT_APP_API_URL}/participants/${producerId}/bills/billing-status?billingPeriodEnd=${billingPeriodEnd}`;
        window.location.href = url;
    };
}
